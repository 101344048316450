// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/foundation-sites/scss/foundation.scss";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i" rel="stylesheet');

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,800,900');

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,900');

$menuWidth:385px;
$smallMenuWidth:300px;

html {
  height: 100%;
}

body {
  height: 100%;
}

body,html{
  overflow-x: hidden;
}

.hidden{
  display: none;
}

ul, ol, dl{
  margin:0;
  padding:0;
}

.relative{
  position:relative;
}

.height100{
  height: 100%;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
.hamburger:hover {
  opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -10px; }
.hamburger-inner::after {
  bottom: -10px; }

.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }


body{
  margin:0 !important;
  padding:0 !important;
  position: relative;
}

.hamburger__div {
  cursor: pointer;
  position: fixed;
  top: 60px;
  left: 60px;
  z-index:666;

  span {
    font-family: "LoveloBlack", serif;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
    line-height:28px;
    display: block;
    float: right;
  }
}

.newsletter_form.error{
  input[type="text"]{
    border-color: #7b0000;
  }

  .progress-button .content::before{
    content: '\f00d';
    color: #7B0000;
  }
}

.progress-button.state-success .content{
  transform: translateY(-100%);
}

.progress-button .content {
  position: relative;
  display: block;
}

.progress-button .content::before,
.progress-button .content::after  {
  position: absolute;
  right: 20px;
  color: #fff;
  font-family: "fontAwesome";
  opacity: 0;
  transition: opacity 0.3s 0.3s;
}

.progress-button .content::before {
  top: 100%;
  right: auto;
  left: 50%;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "\f00c"; /* Checkmark for success */
}

.progress-button.thanks-button .content::before{
  content:"Thanks";
  color:#272727;
  font-family: "Open Sans", sans-serif;
}

.progress-button{
  overflow: hidden;
}

.progress-button.state-success .content::before,
.progress-button.state-error .content::after {
  opacity: 1;
}

.menu{
  z-index:15;
  width: $menuWidth;
  background: #FFF;
  border-right:1px solid #d5d1cf;
  position: fixed;
  height:100%;
  right:100%;
  transition: transform  0.3s linear;
  font-family: "LoveloBlack", sans-serif;
  text-align: center;

  ul{
    list-style-type: none;
    text-transform: uppercase;
    font-weight:bold;
    font-size: 30px;
    width:80%;
    margin: 180px auto 0 auto;
    padding-bottom:30px;
    li{
      margin-top: 20px;
      a{
        font-family:'LoveloBlack', sans-serif;
        text-decoration: none;
        color:inherit;
      }
    }
  }
  .menu__newsletter {
    font-family:'Open Sans', sans-serif;
    margin-top:38px;
    font-size:12px;

    p{
      margin:0;
    }

    p:nth-child(3){
      font-style: italic;
    }

    h3 {
      font-weight:normal;
      font-size:24px;
      margin:0 0 15px 0;
      font-family: "Playfair Display", serif;
    }

    form{
      margin-top: 30px;

      input{
        height:50px;
        width:60%;
        font-size:12px;
        padding-left:20px;
        border:1px solid #e1e1e1;
        -webkit-box-shadow: inset 0 1px 0 #f6f6f6;
        -moz-box-shadow: inset 0 1px 0 #f6f6f6;
        box-shadow: inset 0 1px 0 #f6f6f6;
        margin:0 auto;

        &::-webkit-input-placeholder {
          color: #b7b7b7;
        }

        &:-moz-placeholder { /* Firefox 18- */
          color: #b7b7b7;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
          color: #b7b7b7;
        }

        &:-ms-input-placeholder {
          color: #b7b7b7;
        }
        &:focus{
          outline: none;
        }
      }
      button{
        font-family: 'Open Sans', sans-serif;
        font-size:12px;
        width:35%;
        display: block;
        padding:0;
        margin:20px auto 0 auto;
        text-transform: uppercase;

        background: #272727;
        border:none;
        outline:none;
        color: white;
        height:50px;
        line-height: 50px;
        -webkit-border-radius:5px;
        -moz-border-radius:5px;
        border-radius:5px;
      }
      button:hover{
        background: #464646;
      }
    }
  }

  &.open{
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  $socialHeight:50px;
  .menu__social{
    height:$socialHeight;
    position: relative;
    width:80%;
    margin: 0 auto;
    line-height: $socialHeight;

    img{
      height:32px;
      &:nth-child(1){
        margin-right: 5px;
      }

      &:nth-child(2){
        margin-left: 5px;
      }
    }



    &::after, &::before{
      display: block;
      position: absolute;
      top: $socialHeight/2;
      content:'';
      border-bottom: 1px solid #f0f0f0;
      height:1px;
      width: 30%;
    }

    &::after{
      left:0;
    }

    &::before{
      right:0;
    }
  }
  .menu__infos{

    position: absolute;
    bottom:10px;
    left:10px;
    img {
      opacity: 0.5;
      transition: opacity 0.1s linear;
      &:hover{
        opacity: 1;
      }
    }
  }
}

.content{

  transition: transform  0.3s linear;

  min-height: 100%;
  position: relative;
  &.slide{
    transform: translateX($menuWidth);
  }
}


.content__blackoverlay{
  z-index: 11;
  position:fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  width: 0;
  opacity: 0;
  background: rgba(0,0,0,0.6);
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  &.show{
    opacity: 1;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s;
  }
}

.homepage__wrapper{
  height:100%;
  transition: all 0.3s ease;

  &.slide{
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.homepage__title{
  position: absolute;
  z-index: 10;
  margin-top: 5px;
  margin-left: 10px;
  top:27%;
  left:30%;
  transform: translateX(-50%) translateY(-50%);
  h1 {
    text-shadow: 0 0 12px rgba(0,0,0,.3);
    font-size:90px;
    color:white;
    line-height: 75px;
    font-family: "Lato","Open Sans", sans-serif;
    font-weight: 400;
    text-align: left;
    span{
      letter-spacing: 1.5px;

    }

    span.O{
      position: relative;
      left:-1px;
    }
  }

  .homepage__buttons{
    margin:25px auto 0 auto;
    .homepage__hamburger{
      position: relative;
      float: left;
      width:50px;
      height:70px;
      margin-top:20px;
      margin-left:10px;
      margin-right: 20px;
      .hamburger{
        position: absolute;
      }
      & .hamburger-inner{
        &::after, &::before {
          background: white;
          box-shadow: 0 0 12px rgba(0, 0, 0, .3);
        }
        box-shadow: 0 0 12px rgba(0,0,0,.3);
        background: white;
      }
    }
  }

  .homepage__news__button{
    float:left;
    cursor: pointer;
    height: 69px;
    width:69px;
    background: url("/img/home__news3.png") center center no-repeat;
    text-align:center;
    line-height: 64px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    color:white;
  }

  .homepage__slash{
    float:left;
    width:38px;
    height:71px;
    margin-right:20px;
    background: url('/img/home__slash.png') no-repeat;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide{
      height: 100%;
    }
  }
}



/***ART***/

.art{
  position: absolute;

  top:0;
  bottom:0;
  left:0;
  right:0;

  height: 100%;

  ul.art__menu{
    position: fixed;
    z-index: 8;
    top:120px;
    list-style-type:none;
    width:20%;
    margin: 0;
    padding: 0 0 0 60px;
    color:#8f8f8f;
    transition: color .1s linear;
    li{
      transition: color .1s linear;
      a{
        color:inherit;
        font-family:'Muli', sans-serif;
        font-weight:400;
        font-size:16px;
        &.router-link-active{
          color:black;
        }
      }
    }
    li:hover{
      color:black;
    }
  }

  .colgrid{
    width:15%;
  }

  .art__right{
    position:absolute;
    width:100%;
    height:100%;
    .img{
      position:relative;
      .img__white__overlay{
        position: absolute;
        top:  0;
        left:0;
        bottom: 0;
        right: 0;
        background: rgba(255,255,255,0.2);
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -ms-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;
        opacity:0;
      }

      &:hover .img__white__overlay{
        opacity: 1;
      }

      .videoIcon{
        position: absolute;
        top:11px;
        right:16px;

        img{
          width:24px;
        }
      }
    }
    .art__left_bloc{
      position:fixed;
      left:0;
      bottom:0;
      padding:0 0 60px 60px;
      width:20%;
      .art__left__description{
        margin-bottom:5px;
        font-family:'Open Sans', sans-serif;
        font-size:12px;
      }
      .art__left__controls{
        margin-top: 40px;
        .art__left__nextpres{
          margin-bottom:15px;
        }
        a{
          color:black;
          font-size:12px;
          font-family:'Open Sans', sans-serif;

          &.art__link__prev, &.art__link__next{
            text-transform: uppercase;
            font-weight:bold;
          }
        }
      }
    }
    .art__description{
      text-align: justify;
      font-family:'Open Sans', sans-serif;
      font-size:12px;
    }

    .art__right__bloc{
      svg{
        display:none;
      }
      padding-left:20%;
      .invisible__link__left, .invisible__link__right{
        position: absolute;
        height:100%;
        width:40%;
        z-index: 15;
      }
      .invisible__link__left{
        left:0;
      }
      .invisible__link__right{
        right:0;
      }
    }
    .art__photos{
      padding:30px 30px 0 20%;
    }
  }
  .art__language__block{
    margin-bottom:20px;
    text-align: center;
    color:#b3b3b3; 
    a{
      color:inherit;
      &:hover{
        color: #282828;
      }
      &.active{
        color: #282828;
      }
    }

  }
}


.contact__block{
  width:50%;
  float: left;

  .verticalAlign{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &.contact__left{
    .contact__newsletter_block{
      border-top: 1px solid #f0f0f0;
      h3{
        font-family:"Playfair Display", sans-serif;
        margin:80px 0 27px;
      }
      p{
        font-size:12px;
        font-family:'Open Sans', sans-serif;
      }
    }
    .contact__email__block{

      margin-bottom:50px;
      p{
        a{
          color:inherit;
        }
        a:hover{
          color: #2f2f2f;
        }
        color: #000;
        margin:0;
        font-size:15px;
        font-family: "Open Sans", sans-serif;
      }

      
      h2{
        margin:0;
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 6px;
        font-family: "Playfair Display", sans-serif;
      }

      &.carine__block{
        .contact__facebook__block{
        a {
          &.instagram {
            margin-left: 15px;
          }
          img {
            opacity: 0.75;
            margin-top: 1px;
            height:32px;
            &:hover {
              opacity: 1;
            }
          }
        }

        margin-top: 50px;
       }
        margin-bottom:80px;
      }
    }

    form{
      margin: 53px auto 0 auto;
      width: 71%;
      .error input{
        border-color: #7b0000 !important;
      }
      input {
        height: 50px;
        padding-left: 20px;
        border: 1px solid #e1e1e1;
        box-shadow: inset 0 1px 0 #f6f6f6;
        margin: 0 auto;
        font-family: 'Open Sans', sans-serif;
        font-size:12px;
      }
      button{
        display: block;
        padding:0;
        width:100%;
        text-transform: uppercase;

        background: #272727;
        border:none;
        outline:none;
        color: white;
        height:50px;
        line-height: 50px;
        -webkit-border-radius:5px;
        -moz-border-radius:5px;
        border-radius:5px;
        font-family: 'Open Sans', sans-serif;
        font-size:14px;
      }

      .small-8.columns{
        padding-right: 12px;
        padding-left: 0;
      }

      .small-4.columns{
        padding:0;
      }
    }
  }

  &.contact__right{
    background: #272727;
    height:100%;
    position: absolute;
    right: 0;
    color:#FFF;
    .contact__titleblock{
      text-align: center;
      margin-bottom:40px;
      h3{
        font-size: 30px;
        font-weight: bold;
        margin-bottom:5px;
        font-family:"Playfair Display", sans-serif;
      }
      h4{
        font-size: 12px;
        font-family:"Playfair Display", sans-serif;
      }
    }
    form{

      .error{
        input, textarea{
          border-color: #7b0000;
          margin-bottom: 1px ;
        }
        .message{
          font-family: 'Open Sans', sans-serif;
          font-size: 10px;
          display:block;
          color: #7b0000;
          text-transform: lowercase;
        }
      }

      .message{display: none;}
      label{
        color: #FFF;
        font-family:'Open Sans', sans-serif;
        span {
          color:#b0b0b0;
          font-size: 12px;
        }
      }
      input, textarea{
        color:#FFFFFF;
        background: #272727;
        border:1px solid #dad9d9;
        outline: none;
        box-shadow: none;
        font-family: 'Open Sans', sans-serif;
      }
      textarea{
        font-size: 12px;
        height:100px;
      }
      button{
        display: block;
        padding:0;
        width:25%;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        background: #dad9d9;
        color: #272727;
        border:none;
        outline:none;
        height:50px;
        line-height: 50px;
        -webkit-border-radius:5px;
        -moz-border-radius:5px;
        border-radius:5px;
      }
      input[type="submit"]{
        width:135px;
        height:50px;
        display: block;
        padding: 0;
        text-transform: uppercase;
        border: none;
        outline: none;
        border-radius: 5px;
      }
    }
  }

  &.contact__left{
    text-align: center;
    background: #FFFFFF;
    height:100%;
    position: absolute;
    left: 0;

  }
}

.infos{

  nav.info__nav{
    z-index: 10;
    position: fixed;
    right:60px;
    top:60px;
    //transform: translateY(-50%);
    ul{
      list-style-type: none;
      text-align: right;
      li{
        height:40px;
        line-height:40px;
        .info__dot{
          height:40px;
          width:40px;
          position: relative;
          float: right;
          &::before{
            position: absolute;
            top:50%;
            left:50%;
            margin-left:-4px;
            margin-top: -4px;
            content: '';
            display: block;
            height:5px;
            width:5px;
            border:2px solid black;
            background: #000;
            -webkit-border-radius:50%;
            -moz-border-radius:50%;
            border-radius:50%;
            -webkit-transition: height 0.2s, width 0.2s, margin-top .2s, margin-left .2s;
            -moz-transition: height 0.2s, width 0.2s, margin-top .2s, margin-left .2s;
            -ms-transition: height .2s, width 0.2s, margin-top .2s, margin-left .2s;
            -o-transition: height .2s, width 0.2s, margin-top .2s, margin-left .2s;
            transition: height .2s, width 0.2s, margin-top .2s, margin-left .2s;

          }
          &.active::before{
            margin-top:-6px;
            margin-left:-6px;
            height:12px;
            width:12px;
            background: none;
          }

        }

        a{
          font-size:14px;
          font-family: 'Open Sans', sans-serif;
          color:black;
        }

        &.white{
          a{
            color: white;
          }
          .info__dot::before{
            background: white;
            border-color: white;
          }

          .info__dot.active::before{
            background: none;
          }
        }
      }
    }
  }

  .info__content__desktop{
    .download_cv_button{
      position: absolute;
      padding:0 20px;
      height:50px;
      line-height: 50px;
      left:0;
      bottom:150px;
      font-size: 12px;
      font-weight:600;
      background: white;
      a{
        font-family:"Open Sans", sans-serif;
        display: block;
        height: 100%;
        width:100%;
        color:black;
      }
      img{
        height:20px;
        margin: 0 15px 0 5px;
      }
    }
    .infos__languageswitch{
      text-align: center;
      color: #b3b3b3;
      margin-bottom: 50px;
      a{
        transition: color 0.1s linear;
        color:inherit;
        font-size: 12px;
        font-family:"Muli", sans-serif;
      }
      a.active{
        color:#282828;
      }
    }
    .infos__side__block{
      width:50%;
    }

    .infos__left{
      float:left;
    }

    .infos__right{
      float:right;
    }

    .infos__bio__portrait{
      position: absolute;
      height:100%;
      top:  0;
      bottom:0;
      background: url("/img/bench-left.jpg");
      background-position: center center;
      -webkit-background-size: cover; /* pour anciens Chrome et Safari */
      background-size: cover; /* version standardisée */
    }

    .infos__side__block.infos__right.infos__press__content,.infos__side__block.infos__left.infos__text__content{
      //position: fixed;
      top:100%;
      //bottom:0;
      height: 100%;
      background-position: center center;
      -webkit-background-size: cover; /* pour anciens Chrome et Safari */
      background-size: cover; 
    }

    .infos__side__block.infos__left.infos__press__content{
      text-align: right;
    }

    .infos__side__block.infos__right.infos__press__content{
      background-image:url('/img/press_bg.jpg');
    }

    .infos__side__block.infos__left.infos__text__content{
        background-image: url('/img/text_bg.jpg');
    }

    .infos__text__content, .infos__press__content{
      padding: 100px 50px;

      h3.press__text__title{
        font-family: 'Open Sans', sans-serif;
        margin:0;
        font-weight: 300;
      }

      p{
        margin:0;
      }
    }


    .infos__cv__content__dates{
      padding:70px 40px 0 100px;
    }

    .infos__side__block.infos__left.infos__cv__left{
      width: 50%;
      padding-top:100px;
      padding-left:50px;
    }

    #resume__fr__content, #resume__en__content{
      h2{
        padding:0 40px 0 100px;
      }
    }

    .infos__bio__content{
      padding:50px 12% 20px 120px;
      h2.info__bio__fixed__text{

        font-family:'Open Sans', sans-serif;
        font-weight: 100;
        margin-bottom:50px;
      }
    }

    .infos__cv__left{
      text-align: right;
      font-size:14px;
      line-height: 26px;
      font-family:'Open Sans', sans-serif;
      h2{
        font-family:'Open Sans', sans-serif;
        font-weight: 100;
        margin-bottom:50px;
      }
      .infos__cv__content__title{
        border-right:50px solid #272727;
        text-transform: uppercase;
        height:13px;
        line-height: 13px;
        font-family:"Muli", sans-serif;
        padding-right: 20px;
      }
    }

    .info__content__legal{
      .legal__desktop__content{
        width:55%;
        margin:0 auto;
      }
      margin:100px auto;
      padding-bottom:200px;
      h2{
        text-align: center;
        font-family:'Playfair Display', sans-serif;
      }
    }

    .infos__cv__content{
      position: absolute;
      //bottom:0;
      right:0;
      background: url("/img/bench-right.jpg");
      background-position: center center;
      -webkit-background-size: cover; /* pour anciens Chrome et Safari */
      background-size: cover; /* version standardisée */
      color: #FFF;
      padding-top: 150px;
      .infos__cv__content__title{
        border-left:50px solid white;
        text-transform: uppercase;
        height:13px;
        line-height: 13px;
        font-family:"Muli", sans-serif;
        padding-left: 20px;
      }
      .infos__cv__content__dates{
        padding:100px 350px 0 40px;
      }
    }

    .info__text__press__link{
      margin-bottom:20px;
      font-family: 'Open Sans', sans-serif;
      .info__text__press__link__fr{
        font-size: 14px;
        color:#000;
      }

      .info__text__press__link__en{
        font-size: 12px;
        color: #767676;
      }
      span{
        margin-bottom:-5px;
      }
      a:hover{
        text-decoration: underline;
      }
      a{
        color:inherit;
      }
    }
  }

  .infos__block{
    width:50%;

    &.infos__left{
      height:100%;
      float:left;
    }

    &.infos__right{
      float: right;
      background: #fff;
      color:#000;
    }
  }

  .infos__mobile{
    display: none;
  }

  .infos__desktop{
    display: block;
  }

  .info__background__pablo{
    //position: fixed;
    background: url("/img/PABLOREINOSOPORTRAIT_desktop.jpg");
    background-position: center center;
    -webkit-background-size: cover; /* pour anciens Chrome et Safari */
    background-size: cover; /* version standardisée */
  }

  .info__cv__left{
    //position: fixed;
    top:100%;
  }

  .info__download__block{
    position: absolute;
    padding:0 20px;
    height:50px;
    line-height: 50px;
    right:0;
    top:150px;
    font-size: 12px;
    font-weight:600;
    background: white;
    a{
      font-family:"Open Sans", sans-serif;
      display: block;
      height: 100%;
      width:100%;
      color:black;
    }
    img{
      height:20px;
      margin: 0 15px 0 5px;
    }
  }
}

.news{

  padding-top: 60px;

  .news__main{
    width:60%;
    margin: 50px auto;
    text-align: center;

    .news__main__title, .news__main__subtitle{
      font-family:'Playfair Display', sans-serif;
      h2,h3{
        font-family:inherit;
        line-height: 1em;
        margin-bottom:0;
      }
    }
    .news__main__title{
      h2{
        font-family: "Playfair Display", "Adobe Blank", sans-serif;
        font-weight: 900;
        font-style: normal;
      }
    }
    .news__main__subtitle{
      margin-top:10px;
      h3{
        font-size:1.2em;
      }
    }
    .news__main__date{
      color:#bbbbbb;
      margin-top:50px;
    }
    .news__main__content{
      text-align: initial;
    }

    .news__item__content{
      padding:100px 0;
      border-bottom: 1px solid #f0F0F0;

      &:last-child{
        border:none;
      }
    }
  }

  .newsletter_block{
    width:60%;
    margin:100px auto 150px auto;
    text-align: center;
    padding-top: 100px;
    border-top: 1px solid #f0f0f0;
    h3{
      font-family: 'Playfair Display', sans-serif;
      font-weight: 900;
      margin-bottom:20px;
    }

    p{
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
    }

    form{
      input{
        display: inline;
        height: 50px;
        width: 250px;
        font-size: 12px;
        padding-left: 20px;
        border: 1px solid #e1e1e1;
        box-shadow: inset 0 1px 0 #f6f6f6;
        margin: 0 auto;
      }
      button{
        background: #272727;
        color: white;
        height: 50px;
        width:135px;
        line-height: 50px;
        text-transform: uppercase;
        margin-top: 1px;
        border-radius: 5px;
        font-family: 'Open Sans', sans-serif;
        font-size:12px;
      }
    }
  }

}

.archives{
  padding-top: 140px;
  padding-bottom:200px;
  .archives__main__title{
    h3{
      font-size: 25px;
      font-family:'Montserrat', sans-serif;
      font-weight:700;
      margin: 0 auto;
      text-align: center;
    }
  }

  .archives__list{
    width:60%;
    margin: 0 auto;

    a{
      display: block;
      width:80%;

      .archives__list__title{
        margin-top:20px;
        position: relative;
        height:50px;
        color:black;
        font-family:'Open Sans', sans-serif;
        font-size:24px;
        line-height: 20px;
        .archives__list__title__inner{
          position: absolute;
          bottom:0;
        }
      }

      .archives__list__date{
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size:14px;
        font-weight:900;
        color: #CCC;
        font-family:'Open Sans', sans-serif;
      }
    }

  }

  .archives__year{
    margin-top:120px;
    font-family:'Montserrat', sans-serif;
    font-size:50px;
    span{
      font-weight:bold;
    }
  }

}

.footer{
  clear: both;
  position: absolute;
  bottom:0;
  width:100%;
  height:150px;
  background: #efefef;
  color:#b5b5b5;

  span{
    font-weight:900;
  }
  .footer__left, .footer__right{
    padding:0 5px;
    width:50%;
    float: left;
  }

  .footer__left{
    text-align: right;
  }
}

.footer{

  line-height:14px;
  .footer__left{
    margin-top:55px;
    font-size:  12px;

    span{
      font-size: 18px;
    }
  }
  .footer__right{
    margin-top: 40px;
    font-size:  14px;

    span{
      font-family: 'Playfair Display', sans-serif;
      font-size: 18px;
    }
  }
}

@media screen and (max-height: 880px){

  .infos{
    nav.info__nav{
      right:30px;
      top:30px;
      ul{
        li{
          height:30px;
          line-height:30px;

          .info__dot{
            height:30px;
            width:30px;
          }
        }
      }
    }
  }


  .art{

    .art__right{
      .art__description{
        padding:0 0 30px 30px;
        line-height: 12px;
      }
      .art__left_bloc{
        padding:0 0 30px 30px;
      }
    }

    ul.art__menu{
      top:80px;
      left:0;
      padding:0 0 0 30px;
    }
  }

  .hamburger__div{
    top:30px;
    left:30px;
  }

  .content.slide{

    transform: translateX($smallMenuWidth);

  }

  .menu {
    width:$smallMenuWidth;
    ul{
      font-size:20px;
      margin-top:90px;
      li{
        margin-top:10px;
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {
.infos{
    nav.info__nav{
      display: none;
    }

    .info__content__desktop .infos__bio__content{
      padding: 50px;
    }

    .info__content__desktop{
      .infos__cv__left{
        padding:20px 20px 20px 120px;
      }
    }

    .info__content__desktop .infos__cv__content .infos__cv__content__dates{
      padding: 50px;
    }

    .info__content__bio, .info__content__resume, .info__content__legal{
      display: block !important;
    }

    .info__content__resume .infos__cv__left{
      top:100%;
    }
  }
}

$mobileMenuHeight:100px;

@media only screen and (max-width: 40em) {

  body,html{
    overflow: visible !important;
  }

  .news{
     .news__main{
       width:90%;
     }
  }

  .homepage__title{
    display: none;
  }

  .home__news__block{
    display: none;
  }

  .hamburger__div{
    background: #FFF;
    width:100%;
    height:$mobileMenuHeight;
    top:0;
    left:0;
    padding:30px 40px;

    &.menu-open{
      .hamburger__title{
        display: none;
      }
    }


    .hamburger__title{

      float:left;

      h1{
        text-transform: uppercase;
        font-family: 'Lato', sans-serif;
        font-weight:900;
        font-size:25px;
        line-height: 20px;
        span{
          letter-spacing: 1.5px;
          font-size:inherit;
          margin:0;
          display: inline;
        }
        span.O{
          float:none;
          position: relative;
          left:1px;
        }
      }
    }
    .hamburger{
      float: right;

    }
  }

  .menu {
    height:100%;
    width:100%;
    left:100%;
    right:auto;
    &.open {
      transform: translateX(-100%);
      ul {
        padding-top: 4px;
        text-transform: capitalize;
        font-size: 30px;
        text-align: left;
        margin-left: 50px;
        margin-bottom: 5px;

        border-bottom: none;

        li{
          margin-top:20px;
          &:first-child{
            margin-top: 0;
          }
        }
      }

      .menu__newsletter {
        display: none;
      }
    }

    .menu__infos{
      display: none;
    }

    .menu__social{
      &::after, &::before{
        display: none;
      }
    }
  }


  .content{

    padding-top: $mobileMenuHeight;
    &.slide {
      transform: none;
    }
  }

  .archives .archives__list{
    width:80%;
    a{
      width:100%;
      .archives__list__title{
        line-height:22px;
        height: 70px;
      }
    }
  }

  .art{
    position: relative;

    .art__menu__block{


      background: #F5F5F5;

      .art__mobile__series{
        font-size: 18px;
        font-weight:600;
        font-family: 'Open Sans', sans-serif;
        width:100%;
        text-align: center;
        height:80px;
        line-height:80px;
        i{
          font-size:25px;
          line-height:0;
          margin-right: 10px;
          position: relative;
          bottom:3px;
          color: black;

          &.rotate{
            top:7px;
            bottom:0;
            transform: rotate(180deg);
          }
        }
      }

      .art__menu{
        display: none;
        position: static;
        width:100%;
        text-align: center;
        padding:0 0 30px 0 ;
        font-size: 18px;
        li{
          margin-top:10px;
        }
      }
    }



    .art__mobile__collection__title{
      margin-top:5px;
      text-align: center;
      font-size: 16px;
      height:50px;
      line-height:50px;
    }

    .art__description,.art__left_bloc{
      display: none;
    }

    .colgrid{
      width:48%;
    }

    .art__photos{
      padding:0 30px 0 30px !important;
    }
    .art__image__wrapper{
      position: fixed !important;
      z-index:1111111;
      background: #000;
      top:  0;
      bottom: 0;
      left:0;
      right:0;
      .invisible__link__left,.invisible__link__right{
        top:74px;
      }
      svg{
        padding:10px 0 0 10px ;
        display:block !important;
        margin-top:5px;
        path {
          fill: white;
          transform: scale(1.6);
        }
      }
      .mobile__photo_block{
        position: absolute;top: 74px;bottom: 0px;left: 0px;right: 0px;margin: auto;
      }
    }

    //.art__photos{
    //  .img{
    //    overflow: hidden;
    //    margin-bottom: 10px;
    //    a{
    //      display: block;
    //      height:100%;
    //      width:100%;
    //      position: relative;
    //      img{
    //        position: absolute;
    //        margin: auto;
    //        min-height: 100%;
    //        min-width: 100%;
    //      }
    //    }
    //  }
    //  width:100%;
    //  padding: 20px !important;
    //}
  }

  .infos{
    &.open{
      overflow: visible;
      transform: translateX(-100%);
      .infos__content{
        display: block;
      }
    }
    padding-bottom: 180px;
    transition: transform 0.2s linear;
    .infos__mobile{
      display: block;
      .infos__links__list{
        width:80%;
        margin:50px auto;
        .infos__link__item {
          color:#4a4a4a;
          margin-top:10px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          background: #efefef;
        }
      }
    }

    .infos__desktop{
      display: none;
    }

    .infos__content{
      display: none;
      position: absolute;
      right:-100%;
      top:0;
      width:100%;
      padding:20px 40px;

      .arrow-back{
        margin-bottom:75px;
        height: 50px;
        img{
          height:100%;
        }
      }

      .info__content__block{
        h2{
          font-family:"Open Sans", sans-serif;
          font-weight:300;
          font-size:48px;
          margin-bottom:20px;
        }

        h3.info__mobile__resume__title{
          margin:50px 0 20px 0;
          font-family:"Open Sans", sans-serif;
        }

        p.info__bio__fixed__text{
          font-family: 'Open Sans', sans-serif;

          font-size: 18px !important;

        }

        font-size:24px;
      }
      .info__text__press__link{
        margin-bottom:20px;
        font-family: 'Open Sans', sans-serif;
        .info__text__press__link__fr{
          font-size: 30px;
          color:#000;
        }

        .info__text__press__link__en{
          font-size: 24px;
          color: #767676;
        }
        span{
          margin-bottom:-5px;
        }
        a:hover{
          text-decoration: underline;
        }
        a{
          color:inherit;
        }
      }
    }
  }

  .news .content{
    padding-top: 0;
  }

  .contact__block{
    width:90%;
    margin:0 auto;
    float: none;

    &.contact__left{
      display: none;
    }
    .verticalAlign{
      position: initial;
      transform: none;
    }
    &.contact__right{
      position: initial;
      color: black;
      background: white;
      .contact__titleblock{
        display: none;
      }

      .contact__email__block{
        text-align: center;
        margin-bottom: 20px;
        h2{
          font-family: 'Playfair Display', sans-serif;
          font-weight:900;
        }

        p{
          a{
            color: #272727;
            font-family: 'Open Sans', sans-serif;
          }
        }
      }

      form{
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom:50px;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        label{
          color: #272727;
        }
        input,textarea{
          background:white;
          color: black;
        }
        button{
          background: #272727;
          color: white;
          width:40%;
          .content{
            padding-top:0;
            &::before{
              color: white;
            }
          }
        }
      }

      .contact__newsletter_block{
        text-align: center;
        h3{
          font-family: 'Playfair Display', sans-serif;
          font-size: 25px;
          font-weight: 400;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        p{
          font-size: 12px;
          font-family: 'Open Sans', sans-serif;
        }
        .newsletter_form{
          input{
            font-size: 12px;
          }
          button{
            margin: 0 auto;
            width:40%;
            & span.content::before{
              color: white !important;
            }
          }
          margin:0;
          border: none;
        }
      }
    }

    .contact__facebook__block{
        a {
          &.instagram {
            margin-left: 15px;
          }
          img {
            opacity: 0.75;
            margin-top: 1px;
            height:32px;
            &:hover {
              opacity: 1;
            }
          }
        }

        margin-top: 20px;
       }
  }

  .news{

    .news__item__content:first-child{
      padding: 0 0 100px 0;
    }

    .newsletter_block
    {
      width:90%;
      p{
        width:80%;
        margin:0 auto;
        margin-bottom: 10px;
      }

      form{
        input{
          margin-bottom: 20px;
        }
      }
    }
  }


}
